import { useState, useEffect } from 'react'
import './App.css'
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { FacebookLoginButton } from 'react-social-login-buttons';
import FacebookBusinessLoginButton from './FacebookBusinessLoginButton'


function App() {
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(true);

  // window.FB.getLoginStatus(function (response) {
  //   console.log(response);
  // });


  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: 1167424160608268,
  //       cookie: true,
  //       xfbml: true,
  //       version: 'v16.0',
  //     });

  //     setLoading(false);
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, 'script', 'facebook-jssdk');
  // }, []);


  const configID = 181141151423184;

  const FACEBOOK_APP_ID = 1167424160608268;
  // window.FB.logout(function (response) {
  //   deleteCookie("fblo_" + fbAppId); 
  // });

  // function deleteCookie(name) {
  //   document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // }
  const handleFacebookLogin = async () => {
    try {
      const facebookStatus = await window.FB.getLoginStatus()
      console.log(facebookStatus + "facebook status")
      // Authenticate the user and retrieve an access token
      const response = await window.FB.login(
        // {
        //   config_id: 181141151423184,
        //   auth_type: 'code'
        // }
        );
      console.log(response + "Authenticate the user and retrieve an access token")
      // Send the access token to your server
      const accessToken = response.authResponse.accessToken;
      const data = { accessToken };
      console.log(data + "data")
      await fetch("http://localhost:5000/test/auth/facebook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // Redirect to your app's dashboard
      window.location.href = "/dashboard";
    } catch (error) {
      console.log(error);
    }
  };
  async function handleLogin(accessToken) {
    try {
      const response = await axios.post('http://localhost:5000/api/auth/facebook', {
        accessToken: accessToken,
      });
      console.log(response)
      // Save the user's data and access token as needed
    } catch (error) {
      console.error('Login failed:', error);
    }
  }



  const responseFacebook = () => {
    window.FB.getLoginStatus(function (response) {
      console.log(response.status)
      console.log(response.accessToken)
      if (response.status === 'connected') {
        setAccessToken(response.accessToken);
        console.log("here")
        fetch('http://localhost:5000/api/auth/facebook', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ accessToken: response.accessToken }),
        });
      } else {
        window.FB.login(
          async function (response) {
            await axios.post('http://localhost:5000/test/auth/facebook', { response })
            console.log(response + "facebook login response");
          },
          {
            config_id: 181141151423184,
            auth_type: 'code'
          }
        );
      }
    });

  }
  return (
    <div className="App">
      <div>
        {accessToken ? (
          <p>You are logged in with Facebook.</p>
        ) : (
          <>
            <button onClick={responseFacebook} config_id={configID}  >custom facebook button</button>
            {/* <fb:login-button style={{width:"100px",height:"60px",fontSize:"30px"}} config_id={configID} onlogin={responseFacebook}>
              test
            </fb:login-button> */}
            {/* <LoginButton /> */}
            <p>You NOT are logged in with Facebook.</p>
            <div>
              <FacebookLoginButton onClick={handleFacebookLogin}>
                Login with Facebook
              </FacebookLoginButton>
              <FacebookBusinessLoginButton onLogin={handleLogin} />
            </div>
          </>

        )}
      </div>
    </div>
  )
}

export default App

