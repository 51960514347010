import React, { useEffect } from 'react';

const FacebookBusinessLoginButton = ({ onLogin }) => {
//   useEffect(() => {
//     window.fbAsyncInit = function () {
//       window.FB.init({
//         appId: '1167424160608268',
//         cookie: true,
//         xfbml: true,
//         version: 'v16.0'
//       });
//     };
//   }, []);

  const handleLogin = (response) => {
    if (response.authResponse) {
      onLogin(response.authResponse.accessToken);
    } else {
      console.error('User cancelled login or did not fully authorize.');
      alert('Login failed. Please try again.');
    }
  };

  const handleClick = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        handleLogin(response);
      } else {
        window.FB.login(handleLogin, {config_id: 181141151423184});
      }
    });
  };

  return <button onClick={handleClick}>Login with Facebook Business</button>;
};

export default FacebookBusinessLoginButton;